@media (min-width:320px) and (max-width: 400px) {
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
       .fa-solid{
        margin: 0px ;
        padding: 0px;
       }
       .creative h1{
        font-size: 50px;
       }

    .blog_imner{
        text-align: center;
    }
    .navbar-brand img{
        margin-left: 13px;
    }
   

}
@media (min-width:401px) and (max-width: 499px){
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
      
    .navbar-brand img{
        margin-left: 13px;
    }
   
    
}
@media (min-width:500px) and (max-width: 575px){
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
       .creative h1{
           font-size: 52px;
       }
      
}
@media (min-width:576px) and (max-width: 767px) {
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
       .creative h1{
           font-size: 80px;
       }
       .counter_inner{
        height: 470px;
    }
    .counter-box{
        padding: 4px 0;
    }
    .creative h2{
        font-size: 54px;
    }
    
    
    
}
@media (min-width:992px) and (max-width: 1200px){
    
}



    @media (min-width: 1200px)
    {
        .container{
            width: 1170px;
        }
    }


